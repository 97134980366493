import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - ऋणनिर्देश" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='ऋणनिर्देश'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            १)‌ जगत् निर्मात्याचे, चालकाचे, कुलदेवतेचे, दत्तात्रेयाचे. < br />< br />

२)‌ मातापित्याचे – ज्यांनी उत्तम संस्काराचे बीज पेरले. < br />< br />

३)‌ पत्नीचे – जिने मला सन्मार्गाला लावले, समाधानी वृत्तीचे महत्व पटवले, भक्तिमार्गात सर्वतोपरी सहाय्य केले. < br />< br />

४)‌ प. पू. गजानन महाराज (शेगाव) यांचे – ज्यांनी मला नामजपाचे महत्व पटवले, वेड लावले, त्यातच तल्लीन केले, नाम व रूप यात श्रेष्ठ काय ते पटवले, तसेच “नामजप ऐसा करावा । जेणे गुरू स्वयेच घरी यावा । आपण व्हावे भगवंताचे । एकनिष्ठेने गुरूचे । तेणे ताप न प्रपंचाचे ॥” हे पटवले. “गण गण गणात बोते” मंत्र जपा मुळेच प.पू. पांडुरंग शास्त्री आठवले यांचे गुरू चरण लाभले. < br />< br />

५)‌ संत दासगणू महाराज, श्री. सकळकळे, डॉ. भिंगारकर ह्यांनी गुरू गजानन महाराज यांच्या लीला आपल्या लेखन शैलीने मनावर ठसवल्या. < br />< br />

६)‌ प.पू. पांडुरंग शास्त्री आठवले – ज्यांनी नामजप आसन सिद्ध करून दिले. कृपाशिष देऊन काव्य शक्ती जागृत केली. नामजपात वारंवार दर्शन देऊन आत्मज्ञान नि तत्वज्ञानाच्या चिंतनात ठवले. < br />< br />

७)‌ मुलांचे – नामजप सेवेत एकरूपता साधण्यास माझा पुत्र देवेंद्र व कन्या प्रज्ञा दोघांनी वेळोवेळी सहाय्य केले. < br />< br />

८)‌ हितचिंतक, भक्तगण, ग्रंथास सहाय्यकर्ते, ज्यांच्यामुळे हा ग्रंथ भक्तांच्या हाती आला, त्या सर्वांचा मी ऋणी आहे. < br />< br />

९)‌ इसवी सन १९९८ मध्ये सदर ग्रंथ सुपुत्र श्री. देवेंद्र व त्यांच्या पत्नी सौ. पल्लवी देवेंद्र फडके ह्यांनी अमेरीकेत वास्तव्य असताना कोम्प्यूटर सेटिंग केले. < br />< br />

१०)‌ श्री गुरू आज्ञेनुसार दिनांक २४ फेब्रुवारी २००६, माघ वद्य ११ (विजया एकादशी) शके १९२७ शुक्रवारी ग्रंथ छापण्यास सुरुवात झाली. < br />< br />

११)‌ गजानन महाराजांच्या प्रेरणेने व आशिर्वादाने हा ग्रंथ इंटरनेट वर रवीवार भाद्रपद शुद्ध पंचमी (ऋषीपंचमी) शके १९४२, दिनांक २३ ऑगस्ट २०२० या दिवशी सर्व भक्तांस https://www.ganamaharaj.org/ या वेबसाईट वर उपलब्ध झाला.

</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
